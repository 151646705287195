import type { MathflatApi } from '@mathflat/domain/@entities/Login/api'
import CryptoJS from 'crypto-js'
import Cookies from 'js-cookie'
import { debounce } from 'lodash'
import { deviceType, isMobile, isTablet } from 'react-device-detect'

import { authApi } from '~/@common/api'
import { webviewService } from '~/@common/services'
import authService from '~/@common/services/auth.service'
import { commonRepo } from '~/@common/services/repo.service'
import { localStorageService, sessionStorageService } from '~/@common/services/storage.service'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { envUtils } from '~/@common/utils/envUtils'
import { Logger } from '~/@common/utils/logger'
import maxios from '~/@common/utils/maxios'

import { initAxiosDefaultBaseURL } from './envSetting/envSettings'
import { loadInitialData } from './loadInitialData'
import { openGuideVideoPopup, openPasswordChangePopup } from './popup'
import { initPolyfill } from './shims'

export const initialize = async () => {
  initPolyfill()

  const url = new URL(window.location.toString())
  const searchParams = url.searchParams
  const searchParamsSize = [...url.searchParams.keys()].length

  Logger.setUnauthorizedUser()

  if (searchParamsSize && searchParams.has('hash')) {
    const salt = 'vmfldnlffls1!'
    const ciphertext = searchParams.get('hash')
    const bytes = ciphertext && CryptoJS.AES.decrypt(ciphertext, salt)
    const decodedParams = bytes && JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

    const token = decodedParams?.token
    const studentId = decodedParams?.studentId
    const academyId = decodedParams?.academyId
    const isPersist = decodedParams?.isPersist
    const isInitialPassword = searchParams.get('isInitialPassword')
    const ver = searchParams.get('ver')
    const xPlatform = searchParams.get('xPlatform')

    if (xPlatform) {
      maxios.defaults.headers.common['x-platform'] = xPlatform
      window.freshpaint?.addEventProperties({
        xPlatform: xPlatform,
      })
      window.freshpaint?.identify(studentId, { xPlatform })
    } else {
      window.freshpaint?.addEventProperties({
        xPlatform: 'STUDENT',
      })
      window.freshpaint?.identify(studentId, { xPlatform: 'STUDENT' })
    }

    if (token && academyId && studentId) {
      Logger.setAuthorizedUser({
        academyId,
        relationId: studentId,
        userType: 'STUDENT',
      })

      localStorageService.update({
        user: {
          academyId,
          studentId,
          _isInitialPassword: isInitialPassword,
        },
      })

      if (isPersist) {
        authService.setLocalStorageToken(token)
      } else {
        authService.setSessionStorageToken(token)
      }

      if (ver) {
        localStorageService.update({ device: { ver } })

        if (!isTablet && deviceType === 'mobile') {
          webviewService.requestOrientation('portrait')
        } else {
          webviewService.requestOrientation('landscape')

          const handleResize = debounce(() => {
            if (window.innerWidth < mediaQuery.breakPoint.tablet) {
              webviewService.requestOrientation('portrait')
            } else {
              webviewService.requestOrientation('landscape')
            }
          }, 300)

          window.addEventListener('resize', handleResize)
        }
      }
      await loadInitialData(studentId)
      const url = window.location.protocol + '//' + window.location.host + window.location.pathname
      window.history.replaceState({ path: url }, '', url)
    }
  } else if (authService.isLoggedIn) {
    try {
      if (envUtils.isTestEnv) {
        initAxiosDefaultBaseURL(localStorage.getItem('DEV_API') ?? 'dev')
      }
      await authApi.reissue() // 토큰 유효성 검사

      const { academyId, studentId } = localStorageService.user
      Logger.setAuthorizedUser({
        academyId: (academyId ?? '') as MathflatApi.Response.Login['academyId'],
        relationId: (studentId ?? '') as MathflatApi.Response.Login['relationId'],
        userType: 'STUDENT',
      })

      await loadInitialData(studentId)
    } catch (err) {
      // 예외는 maxios에서 처리함
    }
  } else {
    authService.requestToken()
  }

  if (localStorageService.isFirstUserOnDevice()) {
    // 해당 디바이스에 처음 로그인했다면 동영상 가이드 팝업 노출
    if (!commonRepo.isSchoolflat) {
      openGuideVideoPopup()
    }
  }

  if (
    localStorageService.isInitialPassword &&
    Cookies.get(
      `doNotShowAgainForThreeMonths-password_change-${localStorageService.user.studentId}`,
    ) !== 'true'
  ) {
    openPasswordChangePopup(isMobile)
  }

  sessionStorageService.clearOnAppReload()
}
